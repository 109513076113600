import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Pagination from '@material-ui/lab/Pagination'
import ConfirmModal from '../modal/ConfirmModal'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ExtraContentCellTable from './ExtraContentCellTable'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: '1px solid #F1F3F4'
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.main
    }
  },
  pagination: {
    float: 'right',
    paddingTop: '15px',
    [theme.breakpoints.down('426')]: {
      float: 'unset'
    }
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  tableHead: {
    '& .MuiTableCell-head': {
      color: theme.palette.primary.main
    }
  },
  seeMoreBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    border: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginBottom: '8px',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#F6F7F8',
      borderRadius: '10px'
    }
  }
}))

function Row (props) {
  const {
    id,
    row,
    getChildren,
    childs,
    hasChild,
    childTitle,
    deleteItem,
    childrenIsFetching,
    expandedId,
    setExpandedId,
    childrenPagination,
    extraContent = [],
    permissionEditDelete,
    permissionClone,
    cloneTheme,
    schoolId
  } = props
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElChild, setAnchorElChild] = React.useState(null)
  const [childMenu, setChildMenu] = React.useState(null)
  const [page, setPage] = React.useState(1)
  const [childItems, setChildItems] = React.useState([])

  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    type: null,
    title: null,
    to: null,
    id: null
  })
  React.useEffect(
    () => {
      childs &&
        childs.length > 0 &&
        setChildItems(
          prevState => (prevState ? [...prevState, ...childs] : childs)
        )
    },
    [childs]
  )

  const handleClickOpenMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setAnchorElChild(null)
  }

  const handleClickOpenMenuChild = event => {
    setAnchorElChild(event.currentTarget)
  }

  const handleSetOpen = id => {
    setChildItems([])
    getChildren({ id: id })
    setPage(prevState => (expandedId === id ? 1 : prevState + 1))
    setExpandedId(expandedId === id ? -1 : id)
  }

  const deleteRow = payload => {
    deleteItem(payload)
  }

  const cloneRow = payload => {
    cloneTheme(payload)
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          {hasChild && (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => handleSetOpen(id)}
              aria-expanded={expandedId === id}
            >
              {expandedId === id ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell
          onClick={() => history.push(`${history.location.pathname}/${id}`)}
          component='th'
          scope='row'
        >
          {id}
        </TableCell>
        <TableCell
          onClick={() => history.push(`${history.location.pathname}/${id}`)}
          align='left'
        >
          {row.title}
        </TableCell>
        <ExtraContentCellTable
          classes={classes}
          extraContent={extraContent}
          row={row}
        />
        <TableCell align='left'>
          <MoreVertIcon
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClickOpenMenu}
          />
        </TableCell>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => history.push(`${history.location.pathname}/${id}`)}
          >
            Visualizar
          </MenuItem>
          {permissionClone &&
            history.location.pathname.includes('themes') && (
              <MenuItem
                onClick={() => {
                  setConfirmModal({
                    type: row.type,
                    id: id,
                    title: `Clonar ${row.title}?`,
                    to: 'clone',
                    open: true
                  })
                }}
              >
                Clonar
              </MenuItem>
            )}
          {permissionEditDelete && (
            <MenuItem
              onClick={() =>
                history.push(`${history.location.pathname}/${id}/edit`)
              }
            >
              Editar
            </MenuItem>
          )}
          {permissionEditDelete && (
            <MenuItem
              onClick={() =>
                setConfirmModal({
                  type: history.location.pathname.substring(1),
                  id: id,
                  title: `Deletar ${row.title}?`,
                  to: 'delete',
                  open: true
                })
              }
            >
              Deletar
            </MenuItem>
          )}
        </Menu>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell
          style={{ padding: 0, background: 'rgba(241, 243, 244, 0.7)' }}
          colSpan={6}
        >
          <Collapse in={expandedId === id} timeout='auto' unmountOnExit>
            <Box>
              <Table size='small' aria-label='childs'>
                <colgroup>
                  <col style={{ width: '5%' }} />
                  <col style={{ width: '5%' }} />
                  <col style={{ width: '83%' }} />
                  <col style={{ width: '10%' }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <strong>{childTitle && childTitle}</strong>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childrenIsFetching && <CircularProgress color='secondary' />}
                  {childItems &&
                    childItems.length > 0 &&
                    childItems.map(row => {
                      return (
                        row && (
                          <TableRow key={row.id} className={classes.root}>
                            <TableCell />
                            <TableCell component='th' scope='row'>
                              {row.id}
                            </TableCell>
                            <TableCell>{row.attributes.title}</TableCell>
                            <TableCell align='left'>
                              <MoreVertIcon
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                onClick={e => {
                                  setChildMenu(row.id)
                                  handleClickOpenMenuChild(e)
                                }}
                              />
                            </TableCell>
                            <Menu
                              id='simple-menu'
                              anchorEl={anchorElChild}
                              open={
                                Boolean(anchorElChild) && childMenu === row.id
                              }
                              onClose={() => {
                                setChildMenu(null)
                                handleCloseMenu()
                              }}
                            >
                              <MenuItem
                                onClick={() =>
                                  history.push(`/${row.type}/${row.id}`)
                                }
                              >
                                Visualizar
                              </MenuItem>
                              {permissionEditDelete && (
                                <MenuItem
                                  onClick={() =>
                                    history.push(`/${row.type}/${row.id}/edit`)
                                  }
                                >
                                  Editar
                                </MenuItem>
                              )}
                              {permissionEditDelete && (
                                <MenuItem
                                  onClick={() => {
                                    setConfirmModal({
                                      type: row.type,
                                      id: row.id,
                                      title: `Deletar ${row.attributes.title}?`,
                                      to: 'delete',
                                      open: true
                                    })
                                  }}
                                >
                                  Deletar
                                </MenuItem>
                              )}
                            </Menu>
                          </TableRow>
                        )
                      )
                    })}
                </TableBody>
              </Table>
              {childrenPagination &&
                childrenPagination.page_count > 1 &&
                page <= childrenPagination.page_count && (
                  <Button
                    type='button'
                    onClick={() => {
                      getChildren({ id, page: page })
                      setPage(prevState => prevState + 1)
                    }}
                  >
                    Mais Conteúdos
                  </Button>
                )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmModal
        title={confirmModal.title}
        open={confirmModal.open}
        setOpen={value => {
          if (value) {
            setConfirmModal({ ...confirmModal, open: value })
          } else {
            setConfirmModal({
              type: null,
              id: null,
              title: null,
              to: null,
              open: value
            })
          }
        }}
        onConfirm={() => {
          const afterEffect = () => {
            handleCloseMenu()
            if (childs?.length - 1 > 0) {
              getChildren({ id: id })
            } else {
              setExpandedId(-1)
            }
          }
          if (confirmModal.to === 'delete') {
            deleteRow({
              type: confirmModal.type,
              id: confirmModal.id,
              afterEffect
            })
          } else {
            cloneRow({
              theme_id: confirmModal.id,
              school_id: schoolId
            })
          }
        }}
      />
    </React.Fragment>
  )
}

export default function DataTable ({
  data,
  fetchItems,
  getChildren,
  childs,
  deleteItem,
  hasSchoolId,
  childrenIsFetching,
  itemsIsFetching,
  params,
  childrenPagination,
  ...props
}) {
  const classes = useStyles()
  const [page, setPage] = React.useState(1)
  const [expandedId, setExpandedId] = React.useState(-1)
  const handleChange = (event, value) => {
    setPage(value)
    fetchItems({ page: value, hasSchoolId, ...params })
  }
  return (
    <>
      <TableContainer>
        <Table aria-label='collapsible table'>
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '5%' }} />
            <col
              style={{
                width: props.extraContent?.length
                  ? `${90 / (props.extraContent.length + 1)}%`
                  : '90%'
              }}
            />
            {props.extraContent?.length &&
              props.extraContent.map((extra, _, self) => {
                return (
                  <col
                    key={extra.attr}
                    style={{ width: `${90 / (self.length + 1)}%` }}
                  />
                )
              })}
            <col style={{ width: '10%' }} />
          </colgroup>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell />
              <TableCell>
                <strong>ID</strong>
              </TableCell>
              <TableCell align='left'>
                <strong>Título</strong>
              </TableCell>
              {// eslint-disable-next-line react/display-name
              props.extraContent?.map(extra => {
                return (
                  <TableCell key={extra.title}>
                    <strong>{extra.title}</strong>
                  </TableCell>
                )
              })}
              <TableCell align='left'>
                <strong>Ações</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          {itemsIsFetching ? (
            <CircularProgress color='secondary' />
          ) : (
            <TableBody>
              {data &&
                data.data &&
                data.data.map(row => (
                  <Row
                    key={row.id}
                    id={row.id}
                    row={row.attributes || row}
                    getChildren={getChildren}
                    childs={childs}
                    deleteItem={deleteItem}
                    childrenIsFetching={childrenIsFetching}
                    expandedId={expandedId}
                    setExpandedId={setExpandedId}
                    childrenPagination={childrenPagination}
                    {...props}
                  />
                ))}
            </TableBody>
          )}
        </Table>
        <Pagination
          count={data && data.meta && data.meta.page_count}
          className={classes.pagination}
          color='primary'
          page={page}
          onChange={handleChange}
        />
      </TableContainer>
    </>
  )
}
