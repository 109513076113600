import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  reduxForm,
  Field,
  Form,
  formValueSelector,
  getFormValues
} from 'redux-form'
import CustomTable from '../table/CustomTable'

import {
  getDeletedCoordinators,
  restoreCoordinatorsRSPA
} from '../../store/coordinators/actions'
import Modal from '../modal/Modal'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { showAlertMessage } from '../../store/alert/actions'
import Grid from '@material-ui/core/Grid'
import { Tooltip } from '@material-ui/core'
import filterSVG from '../../assets/icons/filter-icon.svg'
import StyledSVG from '../shared/StyledSVG'
import refreshSVG from '../../assets/icons/refresh.svg'
import MaterialInput from '../shared/MaterialInput'
import Button from '../button/Button'
import { normalizeCPF } from '../../utils/formUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  searchSection: {
    marginTop: 40
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  filtersSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  filtersDisplay: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  horizontalBar: {
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #ADB8CC'
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 136,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  confirmReactiveBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 165,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  action: {
    '& path': {
      stroke: '#868E96'
    }
  },
  btnStyle: {
    marginTop: 18,
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center',
    textAlign: 'right',
    gap: 10,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& button': {
        width: 240
      }
    }
  }
}))

const SearchReactivateCoordinatorForm = ({
  change,
  school,
  deletedCoordinators,
  isFetching,
  isAdmin,
  pagination,
  name,
  email,
  phone,
  restoreCoordinatorsRSPA,
  // eslint-disable-next-line camelcase
  taxpayer_number,
  getDeletedCoordinators
}) => {
  const classes = useStyles()
  const history = useHistory()
  const firstPageAfterSearch = React.useRef(false)
  const selectNameRef = React.useRef(null)
  const selectEmailRef = React.useRef(null)
  const selectTaxpayerRef = React.useRef(null)
  const selectPhoneRef = React.useRef(null)

  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    coordinator: {},
    id: null
  })

  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getDeletedCoordinators({
        params: { ...paramsRequest }
      })
    }
  }

  const defaultParams = {
    'q[school_id_eq]': school?.school_id,
    'q[s]': 'created_at desc'
  }

  const paramsRequest = {
    ...defaultParams,
    'q[coordinator_name_cont]': name,
    'q[coordinator_email_cont]': email,
    'q[coordinator_phone_cont]': phone,
    'q[coordinator_taxpayer_number_cont]': taxpayer_number
  }

  React.useEffect(() => {
    getDeletedCoordinators({ page: 1, params: { ...defaultParams } })
  }, [])

  const handleSearch = isToClear => {
    if (isToClear === true) {
      getDeletedCoordinators({ params: { ...defaultParams } })
      change('name', undefined)
      change('email', undefined)
      change('taxpayer_number', undefined)
      change('phone', undefined)
    } else {
      getDeletedCoordinators({
        params: { ...paramsRequest }
      })
    }
    firstPageAfterSearch.current = true
  }

  const columns = [
    {
      key: 'users',
      name: 'Usuários',
      render: function render (row) {
        return row?.coordinator?.name
      }
    },
    {
      key: 'taxpayer_number',
      name: 'CPF',
      render: function render (row) {
        return row?.coordinator?.taxpayer_number || 'Não informado'
      }
    },
    {
      key: 'phone',
      name: 'Telefone',
      render: function render (row) {
        return row?.coordinator?.phone || 'Não informado'
      }
    },
    {
      key: 'email',
      name: 'Email',
      render: function render (row) {
        return row?.coordinator?.email
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      render: function render (row) {
        return (
          <Tooltip title='Reativar coordenador'>
            <div
              className={classes.action}
              onClick={() => {
                setConfirmModal({
                  id: row?.id,
                  coordinator: row?.coordinator,
                  open: true
                })
              }}
            >
              <StyledSVG width={24} height={24} src={refreshSVG} />
            </div>
          </Tooltip>
        )
      }
    }
  ]

  return (
    <div className={classes.root}>
      <Form>
        <div className={classes.pageLabel}>
          <h1>Reativar coordenador</h1>
          <span>
            Recupere o acesso de coordenadores que já fizeram parte da escola
          </span>
        </div>
        <div className={classes.searchSection}>
          <div className={classes.searchDiv}>
            <strong>Busca</strong>
            <Field
              style={{ marginTop: 10 }}
              component={MaterialInput}
              selectRef={selectNameRef}
              name='name'
              endBtnFunction={() => {
                getDeletedCoordinators({
                  params: { ...paramsRequest }
                })
              }}
              onKeyDown={handleChangeTitle}
              placeholder={'Nome do coordenador'}
              type='text'
            />
          </div>
          <div className={classes.filtersSection}>
            <div className={classes.filterLabel}>
              <StyledSVG src={filterSVG} width={12} height={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  cursor: 'default',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              className={classes.advancedFilter}
              style={{
                height: openMoreFilter ? '250px' : '0px'
              }}
            >
              <div className={classes.filtersDisplay}>
                <div
                  className={classes.searchGroup}
                  style={{ opacity: openMoreFilter ? '1' : '0' }}
                >
                  <div className={classes.searchField}>
                    <label htmlFor='email'>E-mail</label>
                    <Field
                      component={MaterialInput}
                      name='email'
                      selectRef={selectEmailRef}
                      placeholder='Digite o email'
                      type='email'
                    />
                  </div>
                  <div className={classes.searchField}>
                    <label htmlFor={`taxpayer_number`}>CPF</label>
                    <Field
                      allowClear
                      selectRef={selectTaxpayerRef}
                      component={MaterialInput}
                      name={`taxpayer_number`}
                      placeholder='CPF'
                      type='text'
                      autoComplete='off'
                      normalize={normalizeCPF}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          handleSearch(false)
                        }
                      }}
                    />
                  </div>
                  <div className={classes.searchField}>
                    <label htmlFor='phone' className={classes.fieldLabel}>
                      Telefone
                    </label>
                    <Field
                      component={MaterialInput}
                      name='phone'
                      selectRef={selectPhoneRef}
                      placeholder='Telefone'
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          handleSearch(false)
                        }
                      }}
                      type='text'
                      autoComplete='off'
                    />
                  </div>
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={classes.buttons}
                >
                  <Button
                    onClick={() => handleSearch(true)}
                    className={classes.clearBtn}
                  >
                    Limpar
                  </Button>
                  <Button
                    className={classes.applyBtn}
                    type='button'
                    onClick={() => handleSearch(false)}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.horizontalBar} />
        </div>
        <div>
          <h2>Resultados</h2>
          <CustomTable
            columns={columns}
            border={'none'}
            bodyBorder={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            data={deletedCoordinators}
            params={paramsRequest}
            fetchItems={getDeletedCoordinators}
            isFetching={isFetching}
            pagination={pagination}
          />
        </div>
        <div>
          <Grid item xs={12} className={classes.btnStyle}>
            <Button
              className={classes.backBtn}
              variant='outlined'
              type='button'
              onClick={() => history.push('/settings/coordinator')}
            >
              Voltar
            </Button>
          </Grid>
        </div>
        <Modal
          open={confirmModal.open}
          handleClose={() =>
            setConfirmModal({ id: null, coordinator: {}, open: false })
          }
        >
          <div>
            <h3>
              Confirmar a ação restaurará o perfil de coordenador e concederá
              acesso ao sistema.
            </h3>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
            <Button
              className={classes.cancelBtn}
              onClick={() => {
                setConfirmModal({ id: null, coordinator: {}, open: false })
              }}
            >
              Cancelar
            </Button>
            <Button
              className={classes.confirmReactiveBtn}
              onClick={async () => {
                setConfirmModal({ id: null, coordinator: {}, open: false })
                restoreCoordinatorsRSPA({
                  schoolCoordinatorId: confirmModal.id
                })
                setTimeout(
                  () =>
                    getDeletedCoordinators({
                      page: 1,
                      params: { ...defaultParams }
                    }),
                  500
                )
              }}
            >
              Confirmar
            </Button>
          </div>
        </Modal>
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchReactivateCoordinatorForm')
  return {
    email: selector(state, 'email'),
    name: selector(state, 'name'),
    phone: selector(state, 'phone'),
    taxpayer_number: selector(state, 'taxpayer_number'),
    schoolValue: selector(state, 'school_id'),
    currentPagination: state.pagination,
    fieldValues: getFormValues('searchReactivateCoordinatorForm')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  restoreCoordinatorsRSPA: data =>
    dispatch(restoreCoordinatorsRSPA.request(data)),
  getDeletedCoordinators: data => dispatch(getDeletedCoordinators(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({ form: 'searchReactivateCoordinatorForm' })(
    SearchReactivateCoordinatorForm
  )
)
