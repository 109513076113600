import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import DataTable from '../table/Table'
import { roles } from '../../utils/constants'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={2}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    flexGrow: 1,
    alignItems: 'center',
    '& header': {
      boxShadow: 'none !important'
    }
  },
  tab: {
    backgroundColor: '#F1F3F4',
    color: theme.palette.secondary.main,
    padding: '10px 20px',
    border: 'unset'
  },
  btn: {
    padding: 0,
    minHeight: '40px',
    height: '40px',
    textTransform: 'capitalize',
    border: `1px dashed ${theme.palette.secondary.main}`,
    '&.Mui-selected': {
      border: 'none',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '5px',
      color: '#FFF',
      fontWeight: 800
    },
    marginRight: '2px'
  }
}))

export default function SimpleTabs ({
  data,
  fetchItems,
  getChildren,
  childs,
  deleteItem,
  childrenIsFetching,
  itemsIsFetching,
  clearCurrentItem,
  school,
  schoolIsFetching,
  childrenPagination,
  userOccupations,
  search,
  cloneTheme,
  ...props
}) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const schoolName = school ? school.name : 'Escolas'
  const schoolId = school && school.id

  const handleChange = (event, newValue) => {
    setValue(newValue)

    fetchItems({ hasSchoolId: newValue === 1, schoolId: schoolId, ...search })
  }

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='simple tabs example'
          className={classes.tab}
          TabIndicatorProps={{ style: { background: 'none' } }}
        >
          <Tab label='Versar' {...a11yProps(0)} className={classes.btn} />
          {!schoolIsFetching && (
            <Tab label={schoolName} {...a11yProps(1)} className={classes.btn} />
          )}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DataTable
          data={data}
          itemsIsFetching={itemsIsFetching}
          getChildren={getChildren}
          fetchItems={fetchItems}
          childs={childs}
          deleteItem={deleteItem}
          hasSchoolId={value === 1}
          childrenIsFetching={childrenIsFetching}
          clearCurrentItem={clearCurrentItem}
          childrenPagination={childrenPagination}
          params={{ ...search }}
          cloneTheme={cloneTheme}
          schoolId={schoolId}
          permissionClone={
            userOccupations?.includes(roles.SCHOOL_ADMIN) ||
            userOccupations?.includes(roles.CONTENT_CREATOR)
          }
          permissionEditDelete={userOccupations?.includes(roles.VERSAR_ADMIN)}
          {...props}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DataTable
          data={data}
          itemsIsFetching={itemsIsFetching}
          getChildren={getChildren}
          fetchItems={fetchItems}
          childs={childs}
          deleteItem={deleteItem}
          hasSchoolId={value === 1}
          childrenIsFetching={childrenIsFetching}
          clearCurrentItem={clearCurrentItem}
          childrenPagination={childrenPagination}
          params={{ schoolId: schoolId, ...search }}
          permissionEditDelete={
            userOccupations?.includes(roles.VERSAR_ADMIN) ||
            userOccupations?.includes(roles.SCHOOL_ADMIN) ||
            userOccupations?.includes(roles.CONTENT_CREATOR)
          }
          {...props}
        />
      </TabPanel>
    </div>
  )
}
