import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Field,
  Form,
  formValueSelector,
  reduxForm,
  change,
  getFormValues
} from 'redux-form'

import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Tooltip } from '@material-ui/core'
import { roles, statusCommunication } from '../../utils/constants'

import { useQuery, compareValues, numberFromText } from '../../utils/helpers'
import ConfirmModal from '../modal/ConfirmModal'
import ReactSelect from '../select/ReactSelect'
import Button from '../button/Button'
import AsyncSelect from '../shared/AsyncSelect'
import CustomTable from '../table/CustomTable'
import RowActionTable from '../table/RowActionTable'
// import MaterialInput from '../shared/MaterialInput'
import StyledSVG from '../shared/StyledSVG'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import filterSVG from '../../assets/icons/filter-icon.svg'
import listSVG from '../../assets/icons/message-list.svg'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import UndoIcon from '@material-ui/icons/Undo'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 10,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15
    },
    [theme.breakpoints.down('400')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    }
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    }
  },
  typography: {
    [theme.breakpoints.down('426')]: {
      '& p': {
        fontSize: '12px'
      }
    }
  },
  statusField: {
    '& div': {
      [theme.breakpoints.down('426')]: {
        minWidth: 'unset'
      },
      '& .select__control, .MuiInputBase-root': {
        [theme.breakpoints.down('426')]: {
          width: '255px !important'
        },
        [theme.breakpoints.down('370')]: {
          width: '65vw !important'
        }
      }
    }
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  createBtn: {
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  versarAdminBackBtn: {
    fill: '#386093'
  }
}))

const colors = ['#EF7B8E', '#8A588D', '#555555', '#9DD2DA', '#F4A77B']

const SearchMessagesForm = ({
  isFetching,
  pagination,
  schoolId,
  handleSubmit,
  userOccupations,
  schoolValue,
  communications,
  classroomValue,
  getCommunications,
  gradeValue,
  paramsRequest,
  statusValue,
  fieldValues,
  change,
  reset,
  touch,
  userId,
  selectedSchool,
  history,
  initialValues,
  setCurrentStatusFilter,
  updateCommunication
}) => {
  const query = useQuery()
  const classes = useStyles()
  const selectGradeRef = React.useRef(null)
  const selectClassroomRef = React.useRef(null)
  const selectNameRef = React.useRef(null)
  const selectStatusRef = React.useRef(null)
  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const [
    searchParamsTeacherModerator,
    setSearchParamsTeacherModerator
  ] = React.useState(null)
  const [confirmStatusModal, setConfirmStatusModal] = React.useState({
    open: false,
    title: null,
    communicationId: null,
    reactive: null
  })
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  const schoolParams = {
    'q[school_id_in]': schoolId || selectedSchool?.id,
    'q[m]': 'or',
    'q[school_id_null]': true
  }

  React.useEffect(
    () => {
      setCurrentStatusFilter(statusValue)
    },
    [statusValue]
  )

  React.useEffect(
    () => {
      if (query.get('messages') === 'all-message') {
        change('status', null)
      } else {
        change('status', history?.location?.state?.filterBy)
      }
      setOpenMoreFilter(false)
    },
    [query.get('messages')]
  )

  // const handleChangeTitle = (event) => {
  //   getCommunications({
  //     params: {
  //       ...searchParams,
  //       'q[origin_name_cont]': event.target.value
  //     }
  //   })
  // }

  const handleChange = (event, input) => {
    const newValue = event ? event.value : null
    change(input.name, newValue)
  }

  const searchByStatus =
    statusValue && statusValue === 'active'
      ? { 'q[active_eq]': false }
      : !!statusValue && { 'q[active_eq]': true, 'q[status_eq]': statusValue }

  const searchParams = {
    ...paramsRequest,
    ...searchByStatus,
    'q[school_id_in]': schoolId || selectedSchool?.id,
    'q[classroom_id_eq]': classroomValue,
    'q[grade_id_eq]': gradeValue
  }

  const handleSearchMessages = () => {
    setSearchParamsTeacherModerator(null)
    getCommunications({
      params: {
        ...searchParams
      }
    })
  }

  const handleClearFields = () => {
    selectGradeRef?.current?.select?.clearValue()
    selectClassroomRef?.current?.select?.clearValue()
    selectNameRef?.current?.select?.clearValue()
    selectStatusRef?.current?.select?.clearValue()
    history.replace({ state: undefined })
    setSearchParamsTeacherModerator(null)
    getCommunications({
      params: {
        'q[school_id_in]': schoolId || selectedSchool?.id
      }
    })
  }

  const columns = [
    {
      key: 'message',
      name: 'Solicitação',
      width: matches ? '30%' : undefined,
      render: function render (row) {
        const messagesSort = [...row?.messages].sort(compareValues('id'))
        const firstMsg = messagesSort && messagesSort[0]
        return (
          <p
            onClick={() =>
              history.push(`${history.location.pathname}/inbox/${row?.id}`)
            }
            className={classes.msg}
          >
            {firstMsg && firstMsg.title ? (
              firstMsg.title
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    firstMsg?.content &&
                    firstMsg.content
                      ?.split('.')
                      .shift()
                      .substring(0, 65)
                }}
              />
            )}
            {!firstMsg?.title &&
              !firstMsg?.content &&
              firstMsg?.files?.length > 0 &&
              ((firstMsg.files[0].content_type.includes('image') &&
                '[Imagem]') ||
                (firstMsg.files[0].content_type.includes('video') &&
                  '[Vídeo]') ||
                (firstMsg.files[0].content_type.includes('audio') &&
                  '[Audio]') ||
                '[Arquivo]')}
          </p>
        )
      }
    },
    {
      key: 'createdBy',
      name: 'Criada por',
      width: matches ? '15%' : undefined,
      render: function render (row) {
        const index =
          numberFromText(row?.origin?.name?.slice(0, 2)) % colors.length
        return (
          <Tooltip title={row?.origin?.name} aria-label='criada-por'>
            <Avatar
              style={{
                backgroundColor: colors[index]
              }}
            >
              {row?.origin?.name[0]}
            </Avatar>
          </Tooltip>
        )
      }
    },
    {
      key: 'date',
      name: 'Data',
      width: matches ? '15%' : undefined,
      render: function render (row) {
        return <span>{moment(row?.created_at).format('DD/MM/YYYY')}</span>
      }
    },
    {
      key: 'users',
      name: 'Usuários',
      width: matches ? '20%' : undefined,
      render: function render (row) {
        const messagesSort = [...row?.messages].sort(compareValues('id'))
        const msgDestinatios =
          messagesSort &&
          messagesSort[0] &&
          messagesSort[0].message_destinations
        return (
          <AvatarGroup max={4} spacing={1}>
            {msgDestinatios?.length > 0 && msgDestinatios[0].user_id ? (
              msgDestinatios
                .filter(
                  (destination, index, self) =>
                    index ===
                    self.findIndex(us => us.user_id === destination.user_id)
                )
                .map(destination => {
                  const index =
                    numberFromText(destination.user.name?.slice(0, 2)) %
                    colors.length
                  return (
                    <Tooltip
                      key={`user[${destination.user.id}]`}
                      title={destination.user.name}
                      arial-label='usuario'
                    >
                      <Avatar
                        style={{
                          backgroundColor: colors[index]
                        }}
                      >
                        {destination.user?.name[0]}
                      </Avatar>
                    </Tooltip>
                  )
                })
            ) : (
              <div style={{ padding: 5, borderRadius: 10 }}>
                {msgDestinatios?.length > 1 ? 'Turmas' : 'Turma'}
              </div>
            )}
          </AvatarGroup>
        )
      }
    },
    {
      key: 'status',
      name: 'Status',
      width: matches ? '15%' : undefined,
      render: function render (row) {
        return row?.active ? (
          <span
            style={{ color: row.status === 'pending' ? '#F1925B' : '#9B9B9B' }}
          >
            {statusCommunication[row.status]}
          </span>
        ) : (
          <span style={{ color: '#00ACDB' }}>Finalizada</span>
        )
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      width: matches ? '8%' : undefined,
      render: function render (row) {
        const changeStatus = row?.active
        const firstMsg = [...row?.messages]
          .sort(compareValues('id', 'desc'))
          .find(message => !message.message_type.includes('system'))
        return (
          // se for moderador - alterar status e moderar
          // se for só professor - visualizar e responder
          // professor moderador - ver tudo
          row?.active ? (
            <>
              <RowActionTable>
                {(userOccupations.includes(roles.SCHOOL_MODERATOR) ||
                  userOccupations.includes(roles.VERSAR_ADMIN) ||
                  userOccupations.includes(roles.SCHOOL_ADMIN)) && (
                  <>
                    {changeStatus && (
                      <MenuItem
                        onClick={() =>
                          setConfirmStatusModal({
                            communicationId: row?.id,
                            title:
                              'Você deseja realmente alterar o status dessa mensagem para finalizado ?',
                            open: true,
                            reactive: false
                          })
                        }
                      >
                        Alterar Status
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `${history.location.pathname}/inbox/${row?.id}`
                        )
                      }
                    >
                      {row.status === 'ongoing' ? 'Visualizar' : 'Moderar'}
                    </MenuItem>
                  </>
                )}
                {userOccupations.includes(roles.TEACHER) && (
                  <>
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `${history.location.pathname}/inbox/${row?.id}`
                        )
                      }
                    >
                      Visualizar
                    </MenuItem>
                    {row?.active &&
                      row?.status !== 'refused' &&
                      row?.status !== 'pending' &&
                      firstMsg &&
                      !firstMsg.message_type.includes('system') &&
                      firstMsg?.message_destinations?.length === 1 &&
                      !row?.status.includes('done') && (
                        <MenuItem
                          onClick={() =>
                            history.push(
                              `${history.location.pathname}/inbox/${row?.id}`,
                              {
                                toRespond: firstMsg
                              }
                            )
                          }
                        >
                          Responder
                        </MenuItem>
                      )}
                  </>
                )}
              </RowActionTable>
            </>
          ) : (
            <>
              <RowActionTable>
                <MenuItem
                  onClick={() =>
                    history.push(
                      `${history.location.pathname}/inbox/${row?.id}`
                    )
                  }
                >
                  Visualizar
                </MenuItem>
                {(userOccupations.includes(roles.SCHOOL_MODERATOR) ||
                  userOccupations.includes(roles.VERSAR_ADMIN) ||
                  userOccupations.includes(roles.SCHOOL_ADMIN)) && (
                  <MenuItem
                    onClick={() =>
                      setConfirmStatusModal({
                        communicationId: row?.id,
                        title:
                          'Você deseja realmente reativar essa comunicação ?',
                        open: true,
                        reactive: true
                      })
                    }
                  >
                    Reativar comunicação
                  </MenuItem>
                )}
              </RowActionTable>
            </>
          )
        )
      }
    }
  ]

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        {/* Header */}
        <div>
          {/* content */}
          <div>
            {isVersarAdmin && (
              <Tooltip title={'Selecionar escola'}>
                <UndoIcon
                  className={classes.versarAdminBackBtn}
                  onClick={() => history.push('/messages/schools')}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}
            {/* <strong style={{ marginBottom: 10 }}>Busca</strong> */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginBottom: 45
              }}
            >
              {/* <Field
                style={{ width: 300 }}
                component={MaterialInput}
                ref={selectNameRef}
                name='destination_name'
                placeholder='Informe o nome do destinatário'
                onKeyDown={handleChangeTitle}
                type='text'
              /> */}
              <Button
                style={{
                  lineHeight: '16px',
                  whiteSpace: 'nowrap',
                  textTransform: 'none'
                }}
                className={classes.createBtn}
                onclick={() =>
                  history.push(`${history.location.pathname}/create`)
                }
                color='primary'
                id='create_message'
                type='button'
              >
                {`Criar Mensagens`}
              </Button>
            </div>
          </div>
          {/* advanced filters */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <StyledSVG src={filterSVG} height={12} width={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093',
                  cursor: 'default'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              style={{
                height: openMoreFilter ? '250px' : '0px',
                overflow: 'hidden',
                transition: 'height 0.5s ease-in-out'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 24
                }}
              >
                <div
                  className={`${!openMoreFilter ? classes.hidden : ''}`}
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out',
                    display: 'flex',
                    gap: 24
                  }}
                >
                  <div className={classes.searchField}>
                    <strong style={{ marginBottom: 10 }} htmlFor='grade_id'>
                      Série
                    </strong>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      selectRef={selectGradeRef}
                      cacheUniqs={[schoolValue]}
                      id='grade_id'
                      name='grade_id'
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'grade',
                        params: schoolParams
                      }}
                      touch={touch}
                    />
                  </div>
                  <div className={classes.searchField}>
                    <strong style={{ marginBottom: 10 }} htmlFor='classroom_id'>
                      Turma
                    </strong>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      id='classroom_id'
                      name='classroom_id'
                      selectRef={selectClassroomRef}
                      cacheUniqs={[gradeValue, schoolValue]}
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'classroom',
                        params: {
                          'q[school_id_in]': schoolId || schoolValue,
                          'q[grade_id_in]': gradeValue,
                          'q[year_lteq]': new Date().getFullYear() + 1,
                          'q[year_gteq]': new Date().getFullYear()
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                  <div
                    className={`${classes.statusField} ${classes.searchField}`}
                  >
                    <strong style={{ marginBottom: 10 }} htmlFor='status'>
                      Status
                    </strong>
                    <Field
                      component={ReactSelect}
                      selectRef={selectStatusRef}
                      id='status'
                      name='status'
                      isClearable
                      placeholder='Selecione o status'
                      handleChange={handleChange}
                      defaultValue={String(statusValue)}
                      touch={touch}
                      // message status - open: 0, pending: 1, done: 2, refused: 3
                      // communications status - ongoing: 0, pending: 1, done: 2, empty: 3
                      options={[
                        { label: 'Em Andamento', value: '0' },
                        {
                          label: 'Aguardando Moderação',
                          value: '1'
                        },
                        {
                          label: 'Todas mensagens lidas',
                          value: '2'
                        },
                        { label: 'Finalizado', value: 'active' }
                      ]}
                    />
                  </div>
                  <div />
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={`${classes.buttons} ${
                    !openMoreFilter ? classes.hidden : ''
                  }`}
                >
                  <Button
                    className={classes.clearBtn}
                    onClick={() => {
                      handleClearFields()
                      setOpenMoreFilter(false)
                    }}
                  >
                    Limpar
                  </Button>
                  <Button
                    onClick={handleSearchMessages}
                    type='button'
                    className={classes.applyBtn}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* horizontal bar */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              height: 1,
              border: '#ADB8CC solid 1px'
            }}
          />
        </div>
      </Form>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <StyledSVG src={listSVG} height={24} width={24} />
        <h1 style={{ color: '#386093' }}>{'Lista de mensagens'}</h1>
      </div>
      <CustomTable
        from={'messages'}
        columns={columns}
        data={communications.items}
        columnsPointer={[0]}
        border={'none'}
        bodyBorder={'none'}
        headCellRadius
        headColor={'rgba(173, 184, 204, 0.3)'}
        isFetching={isFetching}
        fetchItems={getCommunications}
        params={searchParamsTeacherModerator || searchParams}
        pagination={communications.pagination}
      />
      <ConfirmModal
        title={confirmStatusModal.title}
        open={confirmStatusModal.open}
        setOpen={value => {
          if (value) {
            setConfirmStatusModal({ ...confirmStatusModal, open: value })
          } else {
            setConfirmStatusModal({
              communicationId: null,
              title: '',
              open: value,
              reactive: null
            })
          }
        }}
        onConfirm={() => {
          confirmStatusModal.communicationId &&
            updateCommunication({
              id: confirmStatusModal.communicationId,
              active: !!confirmStatusModal.reactive,
              ...(confirmStatusModal.reactive === true && { status: 'ongoing' })
            })
        }}
      />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchMessagesForm')
  const initValues = {
    // status - ongoing: 0, pending: 1, done: 2, empty: 3
    status:
      (props?.history?.location?.state?.filterBy === 1 && '1') ||
      (props?.history?.location?.state?.filterBy === 0 && '2') ||
      (props?.history?.location?.state?.filterBy === 'active' && 'active') ||
      null
  }
  return {
    initialValues: initValues,
    userOccupations: state.auth.currentOccupation,
    userId: state.auth.user.data && state.auth.user.data.id,
    schoolId:
      state.school.currentSchool && state.school.currentSchool.school_id,
    schoolValue:
      selector(state, 'school_id') ||
      (state.school.currentSchool && state.school.currentSchool.school_id),
    classroomValue: selector(state, 'classroom_id'),
    gradeValue: selector(state, 'grade_id'),
    statusValue: selector(state, 'status'),
    fieldValues: getFormValues('searchMessagesForm')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('searchMessagesForm', field, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchMessagesForm'
  })(SearchMessagesForm)
)
