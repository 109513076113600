import React, { useEffect } from 'react'
import { Waypoint } from 'react-waypoint'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ButtonStyled } from '../form/styles/CreateModuleForm.style'
import AddIcon from '@material-ui/icons/Add'
import Btn from '../button/Button'

import Card from '../card/Card'
import Label from '../label/Label'
import jsonApi from '../../utils/jsonApi'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '2rem',
    '& a': {
      textDecoration: 'none'
    },
    '& h3': {
      color: theme.palette.primary.main
    }
  },
  addClassroom: {},
  label: {
    marginBottom: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('426')]: {
      flexDirection: 'column'
    }
  },
  control: {
    padding: theme.spacing(2)
  },
  list: {
    maxWidth: 780,
    flexBasis: '75%',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 20%)',
      rowGap: '1rem',
      maxWidth: '90%',
      flexBasis: '100%'
    },
    [theme.breakpoints.down('1750')]: {
      gridTemplateColumns: 'repeat(4, 25%)',
      columnGap: '0.5rem'
    },
    [theme.breakpoints.down('1200')]: {
      gridTemplateColumns: 'repeat(3, 33%)',
      columnGap: '0.5rem'
    }
    /* [theme.breakpoints.up('xl')]: {
      maxWidth: '70%'
    } */
  },
  cardButton: {
    margin: '0 10px',
    '& div.MuiPaper-root': {
      minWidth: '240px',
      width: '100%',
      height: '15rem',
      marginLeft: '0',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10
      }
    }
  },
  content: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridGap: '1rem',
    '&.MuiCardContent-root:last-child': {
      width: '100%',
      textAlign: 'center',
      height: '100%',
      'align-items': 'center'
    }
  },
  title: {
    fontSize: '1.5rem',
    lineHeight: '40px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      lineHeight: '26px'
    }
  },
  body: {
    fontSize: '1rem'
  },
  nested: {
    color: theme.palette.primary.main
  },
  schoolFilter: {
    '& span': {
      'font-weight': 'bold'
    }
  },
  schoolList: {
    display: 'flex',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('1240')]: {
      flexWrap: 'wrap'
    }
  },
  link: {
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'none',
    width: 'fit-content',
    paddingBottom: '4px',
    'white-space': 'pre',
    'text-overflow': 'ellipsis',
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      fontWeight: 800,
      background: 'none',
      paddingBottom: 0
    }
  },
  active: {
    color: theme.palette.secondary.main,
    fontWeight: 800,
    background: 'none',
    paddingBottom: 4
  }
}))

const initialState = {
  items: [],
  school: {
    options: [],
    filter: ''
  },
  pagination: {
    current: 1,
    totalPages: 1,
    per: 12,
    hasNextPage: true
  }
}

const reducer = (state, action) => {
  return { ...state, [action.property]: action.value }
}

const ClassroomsView = ({ occupations, currentSchool }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const isVersarAdmin = occupations?.includes('administrator')
  const isAdmin = occupations?.includes('school_administrator') || isVersarAdmin
  const {
    items,
    school: { options: schoolOptions, filter },
    pagination: { current, totalPages, per, hasNextPage }
  } = state
  const classes = useStyles()
  const defaultParams = {
    'page[number]': initialState.pagination.current,
    'page[size]': per,
    include: 'children,school,grade',
    sort: 'grade_id',
    'q[school_id_eq]': currentSchool.school_id || initialState.school.filter,
    'q[year_lteq]': new Date().getFullYear() + 1,
    'q[year_gteq]': new Date().getFullYear()
  }
  useEffect(() => {
    isVersarAdmin && getSchools()
    getData({ params: defaultParams })
  }, [])
  useEffect(
    () => {
      dispatch({ property: 'items', value: [] })
      dispatch({
        property: 'pagination',
        value: {
          ...state.pagination,
          current: 1,
          totalPages: 1,
          hasNextPage: true
        }
      })
      const params = {
        ...defaultParams,
        'q[school_id_eq]': filter || currentSchool.school_id
      }
      getData({ params, hasNxtPage: true, changeSchool: true })
    },
    [filter]
  )
  const getSchools = async () => {
    jsonApi.findAll('school').then(res => {
      if (res.data) {
        dispatch({
          property: 'school',
          value: {
            ...state.school,
            options: res.data.map(({ id, name }) => ({ id, name }))
          }
        })
      }
    })
  }
  const getData = async ({
    params,
    hasNxtPage = hasNextPage,
    changeSchool = false
  }) => {
    if (!hasNxtPage) return
    jsonApi
      .findAll('classroom', {
        params: { ...params }
      })
      .then(res => {
        if (res.data) {
          const usedItems = changeSchool ? [] : items
          const itemsLength = changeSchool ? 0 : usedItems.length
          let newHasNextPage = true
          let newPage = current
          if (res.meta.page_count * 1 > current) {
            newPage = params['page[number]']
          }
          if (res.meta.total_count === itemsLength + res.data.length) {
            newHasNextPage = false
          }
          dispatch({
            property: 'pagination',
            value: {
              ...state.pagination,
              totalPages: res.meta.page_count,
              hasNextPage: newHasNextPage,
              current: newPage
            }
          })
          const parsedData = res.data.filter(
            item => !usedItems.some(thisItem => thisItem.id === item.id)
          )
          dispatch({ property: 'items', value: [...usedItems, ...parsedData] })
        }
      })
  }

  const loadMoreData = () => {
    if (current < totalPages) {
      const params = {
        ...defaultParams,
        'page[number]': current + 1,
        'q[school_id_eq]': filter || currentSchool.school_id
      }
      getData({ params })
    } else {
      dispatch({
        property: 'pagination',
        value: {
          ...state.pagination,
          hasNextPage: false
        }
      })
    }
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.label}>
          <Label>Minhas Turmas</Label>
          {isVersarAdmin && (
            <List
              component='ul'
              aria-label='top nav'
              className={classes.schoolList}
              disablePadding
            >
              <ListItem>
                <ListItemText
                  className={classes.schoolFilter}
                  primary='Exibir'
                />
              </ListItem>
              <ListItem
                activeClassName='Mui-selected'
                onClick={() =>
                  dispatch({
                    property: 'school',
                    value: { ...state.school, filter: '' }
                  })
                }
                className={`${classes.link} ${classes.nested} ${filter === '' &&
                  classes.active}`}
              >
                <ListItemText primary='Todas as Escolas' />
              </ListItem>

              {!!schoolOptions.length &&
                schoolOptions.map(school => (
                  <ListItem
                    key={school.name}
                    activeClassName='Mui-selected'
                    onClick={() =>
                      dispatch({
                        property: 'school',
                        value: { ...state.school, filter: school.id * 1 }
                      })
                    }
                    className={`
                    ${classes.link}
                    ${classes.nested}
                    ${filter === school.id * 1 && classes.active}
                  `}
                  >
                    <ListItemText primary={school.name} />
                  </ListItem>
                ))}
            </List>
          )}
        </Grid>
        {isAdmin && (
          <Link
            style={{ marginBottom: '20px' }}
            to={'/settings/classrooms/new'}
          >
            <ButtonStyled>
              <Btn
                style={{ width: '258px', backgroundColor: '#00ACDB' }}
                startIcon={<AddIcon />}
              >
                Adicionar Turma
              </Btn>
            </ButtonStyled>
          </Link>
        )}
        <Grid item xs={12} sm={12} container>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: ' 100%'
            }}
          >
            <Grid item xs={12} sm={4} className={classes.list}>
              {items.length === 0 ? (
                <h2>Não há turmas cadastradas</h2>
              ) : (
                items.map(item => {
                  return (
                    <Link
                      className={classes.cardButton}
                      key={item.id}
                      to={`/classrooms/${item.id}?name=activities`}
                    >
                      <Card color='secondary'>
                        <CardContent className={classes.content}>
                          <div>
                            <Typography
                              className={classes.title}
                              variant='h5'
                              component='h2'
                            >
                              {item?.grade?.name}
                            </Typography>
                            <Typography
                              className={classes.title}
                              variant='h5'
                              component='h2'
                            >
                              {item.name}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              className={classes.body}
                              variant='body2'
                              component='p'
                            >
                              {item.children?.length} alunos
                            </Typography>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  )
                })
              )}
              {hasNextPage && (
                <Waypoint onEnter={loadMoreData}>
                  <CircularProgress color='secondary' />
                </Waypoint>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ClassroomsView
